import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Profile from "../components/Profile";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`About`}</h2>
    <Profile name="もちお" nickname="スピナー専門家" icon="/images/icon-mochio.jpg" weapons={["バレルスピナーリミックス", "ダイナモローラーベッチュー", "ラピッドブラスターベッチュー", "おちばシューター"]} mdxType="Profile" />
    <Profile name="*リサ" nickname="ザップ大臣" icon="/images/icon-lisa.jpg" weapons={["N-ZAP85"]} mdxType="Profile" />
    <Profile name="*たけ" nickname="逃げ腰のたけ" icon="/images/icon-take.jpg" weapons={["ヒーローチャージャー レプリカ", "リッター4K", "スパッタリー・ヒュー", "スパッタリークリア", "ジェットスイーパー"]} mdxType="Profile" />
    <Profile name="うな" nickname="ハイカラの頭脳" icon="/images/icon-una.jpg" weapons={["スパッタリー・ヒュー", "スパッタリークリア"]} mdxType="Profile" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      