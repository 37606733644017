import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Iframe from "../components/Iframe";
import ProjectPicture from "../components/ProjectPicture";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Projects`}</h2>
    <ProjectCard title="3周年記念画像" link="/images/3rd-anniversary.jpg" bg="linear-gradient(to right, #07007d 0%, #00D4FF 100%)" mdxType="ProjectCard">
  スナックハイカラ3周年の記念画像。*リサが制作。(2022.03.27)
  <ProjectPicture src="/images/3rd-anniversary.jpg" mdxType="ProjectPicture" />
    </ProjectCard>
    <ProjectCard title="2周年記念動画" link="https://youtu.be/EHA1hwosSbQ" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  スナックハイカラ2周年の記念動画。*たけが制作。(2021.03.28)
  <Iframe src="https://www.youtube.com/embed/EHA1hwosSbQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" mdxType="Iframe"></Iframe>
    </ProjectCard>
    <ProjectCard title="キル集 (うな)" link="https://youtu.be/0xSa6jUv6jQ" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  *たけが制作。(2021.08.22)
  <Iframe src="https://www.youtube.com/embed/0xSa6jUv6jQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" mdxType="Iframe"></Iframe>
    </ProjectCard>
    <ProjectCard title="キル集 (*たけ)" link="https://youtu.be/62C1ddBVANE" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  *たけが制作。(2021.12.30)
  <Iframe src="https://www.youtube.com/embed/62C1ddBVANE" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" mdxType="Iframe"></Iframe>
    </ProjectCard>
    {
      /*
      <ProjectCard
       title="Theme UI Plugin for Figma"
       link="https://www.lekoarts.de/design/introducing-the-theme-ui-plugin-for-figma?utm_source=cara&utm_medium=Theme"
       bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
      >
       The Theme UI plugin for Figma allows for a workflow where Theme UI is the
       starting point both for design & development.
      </ProjectCard>
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      