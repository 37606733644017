import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`History`}</h2>
    <ul>
      <li parentName="ul">{`2022年5月 `}<a parentName="li" {...{
          "href": "http://picoparkgame.com/"
        }}>{`PICO PARK`}</a></li>
      <li parentName="ul">{`2022年4月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1519621213676781568"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチホコバトル (最高リーグパワー 1822.1)`}</li>
      <li parentName="ul">{`2022年4月 スプラトゥーン2 対抗戦 VS `}<a parentName="li" {...{
          "href": "/isaribikai"
        }}>{`いさりび会`}</a></li>
      <li parentName="ul">{`2022年3月27日 スプラトゥーン2 リーグマッチ ガチヤグラ (最高リーグパワー 1926.5)`}</li>
      <li parentName="ul">{`2022年3月 スプラトゥーン2 交流戦 with `}<a parentName="li" {...{
          "href": "/isaribikai"
        }}>{`いさりび会`}</a>{` (`}<a parentName="li" {...{
          "href": "https://twitter.com/pokopon_peco/status/1508075536999845893"
        }}>{`仲間割れ事件`}</a>{`)`}</li>
      <li parentName="ul">{`2022年1月 スプラトゥーン2 対抗戦 VS `}<a parentName="li" {...{
          "href": "/isaribikai"
        }}>{`いさりび会`}</a></li>
      <li parentName="ul">{`2021年12月 スナックハイカラ オープン🍻✨ スプラトゥーン2 サーモンラン、Ultimate Chicken Horse`}</li>
      <li parentName="ul">{`2021年11月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1458359544875208704?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチヤグラ (最高リーグパワー 1903.9)`}</li>
      <li parentName="ul">{`2021年10月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1446400747814412292?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチエリア (最高リーグパワー 1952.5)`}</li>
      <li parentName="ul">{`2021年8月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1424656970003406850?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチホコバトル (最高リーグパワー 1877.3)`}</li>
      <li parentName="ul">{`2021年8月 スプラトゥーン2 リーグマッチ (最高リーグパワー 2070.0)`}</li>
      <li parentName="ul">{`2021年7月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1413415395751567362?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチヤグラ (最高リーグパワー 1971.7)`}</li>
      <li parentName="ul">{`2021年6月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1402551815388999683?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチエリア (最高リーグパワー 1870.7)`}</li>
      <li parentName="ul">{`2021年5月 スプラトゥーン2 チャクチ狩り練習プラベ`}</li>
      <li parentName="ul">{`2021年5月 オーバークック、マリオカート8 デラックス`}</li>
      <li parentName="ul">{`2021年5月 Ultimate Chicken Horse`}</li>
      <li parentName="ul">{`2021年3月 2周年記念動画完成 🎉 `}</li>
      <li parentName="ul">{`2021年1月 スプトゥーン2 フェス`}</li>
      <li parentName="ul">{`2020年12月 オーバークック2`}</li>
      <li parentName="ul">{`2020年10月 スプラトゥーン2 フェス`}</li>
      <li parentName="ul">{`2020年10月 Left 4 Dead 2 🧟`}</li>
      <li parentName="ul">{`2020年9月 第1回 オンラインオフ会 🍻`}</li>
      <li parentName="ul">{`2020年9月 スプラトゥーン2 対抗戦`}</li>
      <li parentName="ul">{`2020年6月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1269911290857820160?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチエリア (最高リーグパワー 2001.7)`}</li>
      <li parentName="ul">{`2020年4月 あつまれ どうぶつの森`}</li>
      <li parentName="ul">{`2020年3月 Minecraft スナックハイカラからの脱出`}</li>
      <li parentName="ul">{`2020年3月 Ultimate Chicken Horse 🐔🐴`}</li>
      <li parentName="ul">{`2020年2月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1224263671729266688?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチエリア (最高リーグパワー 1966.5)`}</li>
      <li parentName="ul">{`2019年11月 スプラトゥーン2 リーグマッチ (最高リーグパワー 2016.2)`}</li>
      <li parentName="ul">{`2019年11月 イラストチェイナー`}</li>
      <li parentName="ul">{`2019年10月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1179330589402501122?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチエリア (最高リーグパワー 1832.9)`}</li>
      <li parentName="ul">{`2019年10月 スプラトゥーン2 リーグマッチ ガチヤグラ (最高リーグパワー 1837.0)`}</li>
      <li parentName="ul">{`2019年10月 スプラトゥーン2 リーグマッチ ガチホコバトル (最高リーグパワー 1946.9)`}</li>
      <li parentName="ul">{`2019年10月 Minecraft スナックハイカラ はじまりのち`}</li>
      <li parentName="ul">{`2019年10月 マリオカート8 デラックス`}</li>
      <li parentName="ul">{`2019年9月 スプラトゥーン2 `}<a parentName="li" {...{
          "href": "https://twitter.com/SplatoonJP/status/1168812167417622528?s=20"
        }}>{`ツキイチ・リーグマッチ`}</a>{` ガチアサリ (最高リーグパワー 2011.7)`}</li>
      <li parentName="ul">{`2019年7月 ディスコード サーバー オープン`}</li>
      <li parentName="ul">{`2019年3月27日 `}<a parentName="li" {...{
          "href": "https://twitter.com/_mochi0/status/1109054824006377472"
        }}>{`スナックハイカラ結成`}</a>{` 🍾✨`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      